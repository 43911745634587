import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class UserService {

  private menu = new BehaviorSubject(false);
  changeMenu = this.menu.asObservable();

  private fullPage = new BehaviorSubject(false);
  changeScreen = this.fullPage.asObservable();
  
  constructor(private http: HttpClient) { }

  setSiteMenu(isChange: boolean) {
    this.menu.next(isChange);
  }

  setSiteFullScreen(isChange: boolean) {
    this.fullPage.next(isChange);
  }

  getUserList(paggination): Observable<any> {
    let apiUrl=environment.apiUrl+'/User/getByFilter?keyword='+paggination.keyword+'&size='
    +paggination.size+'&page='+paggination.page+'&excludeDeleted='
    +paggination.excludeDeleted+'&sort='+paggination.sortColumn+'&asc='+paggination.sort
    +'&isEndUserList='+paggination.isEndUserList;

    if(paggination.status && paggination.status!=='0') {
      apiUrl=apiUrl+'&status='+paggination.status;
    }
      return this.http.get(apiUrl).pipe(catchError((error, caught) => {
        return of(error);
      }) as any);
    }

    getAllUser(): Observable<any> {
      return this.http.get(environment.apiUrl+'/User').pipe(catchError((error, caught) => {
        return of(error);
      }) as any);
    }

    activeDeActive(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Account/Deactivate/' + id).pipe(catchError((error, caught) => {
        return of(error);
      }) as any);
    }

    deleteUser(userId): Observable<any> {
      return this.http.delete(environment.apiUrl + '/Account/Delete/'+userId).pipe(catchError((error, caught) => {
        return of(error);
      }) as any);
    }

    getUserDetail(userId): Observable<any> {
      return this.http.get(environment.apiUrl+'/User/getUserProfile/'+userId).pipe(catchError((error, caught) => {
        return of(error);
      }) as any);
    }

    createUser(user): Observable<any> {
      return this.http.post(environment.apiUrl + '/Account/CreateUser', user).pipe(catchError((error, caught) => {
        return of(error);
      }) as any);
    }

    getCountryCode(): Observable<any> {
      return this.http.get('assets/country/CountryCodes.json').pipe(catchError((error, caught) => {
        return of(error);
      }) as any);
    }

    loadFriendList(userId,pageSize,pageNumber): Observable<any> {
      return this.http.get(environment.apiUrl+'/Friendship/GetFriendsByUserId?page='+pageNumber+'&size='+pageSize+'&keyword=&sort=firstname&asc=true&friendRequestStatus=&UserId='+userId).pipe(
      catchError((error, caught) => {
        return of(error);
      }) as any);
    }
}
