import { Inject, Injectable, Injector } from '@angular/core';
import { NbAuthToken } from '@nebular/auth';
import { NbAuthService } from '@nebular/auth';
import { NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import {
  throwError as observableThrowError,
  Observable,
} from 'rxjs';

import { catchError, switchMap, finalize, tap } from 'rxjs/operators';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class NgxAuthJWTInterceptor implements HttpInterceptor {
  isRefreshingToken: boolean = false;
  constructor(private injector: Injector,
    private http: HttpClient,
    private router: Router,
    @Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) protected filter) {
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    // do not intercept request whose urls are filtered by the injected filter
    if (!this.filter(req)) {
      return this.authService.getToken().pipe(
        switchMap((token: NbAuthToken) => {
          const JWT = `Bearer ${token.getValue()}`;
          if(req.url.startsWith(environment.apiUrl))
          {
            req = req.clone({
              setHeaders: {
                Authorization: JWT,
              },
            });
          }
         
          return next.handle(req).pipe(
            tap(evt => {
              if (evt instanceof HttpResponse) {
                if(evt.body && evt.body.data && evt.url.includes('AdminLogin') ) {
                  localStorage.setItem('refreshToken', evt.body.data.refresh_token);
                } 
              }
            }),
            catchError(error => {
              if (error instanceof HttpErrorResponse) {
                switch ((<HttpErrorResponse>error).status) {
                  case 400:
                    return this.handle400Error(error);
                  case 401:
                    return this.handle401Error(req, next);
                  case 500: {
                    return observableThrowError(error);
                  }
                  default:
                    return observableThrowError(error);
                }
              } else {
                return observableThrowError(error);
              }
            }),
          );
        }),
      );
    } else {
      return next.handle(req).pipe(
        tap(evt => {
          if (evt instanceof HttpResponse) {
            if(evt.body && evt.body.data && evt.url.includes('AdminLogin')) {
              localStorage.setItem('refreshToken', evt.body.data.refresh_token);
            } 
          }
        }));
    }
  }

  handle400Error(error) {
    if (
      error &&
      error.status === 400 &&
      error.error &&
      error.error.error === 'invalid_grant'
    ) {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return observableThrowError(error);
    }

    return observableThrowError(error);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      return this.refreshToken().pipe(
        switchMap(newToken => {
         return this.authService.refreshToken('email', { token: newToken.data.access_token })
            .pipe(
              switchMap(authenticated => {
                localStorage.setItem('refreshToken', newToken.data.refresh_token);
                this.isRefreshingToken = false;
                const JWT = `Bearer ${newToken.data.access_token}`;
                req = req.clone({
                  setHeaders: {
                    Authorization: JWT,
                  },
                });
                return next.handle(req);
              }));
        }),
        catchError(error => {
          return this.logoutUser();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
    } else { 
      return this.logoutUser();
    }
  }

  refreshToken(): Observable<any> {
    const param = {
      'refreshToken': localStorage.getItem('refreshToken'),
    };
    return this.http.post(environment.apiUrl + '/Account/RefreshToken', param);
  }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }

  logoutUser() {
    // Route to the login page (implementation up to you)
    this.authService.logout('email');
    return this.router.navigate(['auth/login']);
  }
}

